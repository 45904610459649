import * as React from "react"
import Markdown from "react-markdown"
import { useSelector, useDispatch } from "react-redux"

import * as styles from "../assets/css/home.module.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Ideas from "../components/ideas/ideas"
import GlassParagraph from "../components/glassParagraph/glassParagraph"
import { Strings } from "../constants/strings"
import { getMode } from "../redux/slices/modeSlice"
import { getLanguage } from "../redux/slices/languageSlice"
import { fetchCustomer } from "../redux/slices/customerSlice"
import {
  fetchHomepageEntities,
  selectHomepageEntities,
} from "../redux/slices/homePageSlice"
import { fetchSolutions, getSolutions } from "../redux/slices/solutionsSlice"

const SolutionsPage = () => {
  //REDUX STUFF
  const mode = useSelector(getMode)
  const language = useSelector(getLanguage)
  const solutions = useSelector(getSolutions)
  const entities = useSelector(selectHomepageEntities)
  //DATAS TO SHOW ON THE PAGE
  const [services, setServices] = React.useState(0)
  const dispatch = useDispatch()

  const loadData = React.useCallback(() => {
    try {
      if (solutions.loading === "idle") {
        dispatch(fetchSolutions())
      }
    } catch (error) {
      console.log(error)
    }
  }, [solutions, dispatch])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  React.useEffect(() => {
    dispatch(fetchCustomer())
  }, [dispatch])

  React.useEffect(() => {
    const url = new URLSearchParams(window.location.search)
    let active = url.get("active")
    console.log("___ACTIVE", +active)
    if (active) {
      setServices(+active)
    } else if (solutions.solutions.length) {
      setServices(solutions.solutions[0].id)
    } else {
    }
  }, [solutions])

  const selectedSolution = React.useMemo(() => {
    return solutions.solutions.find(elem => elem.id === services)
  }, [solutions, services])

  return (
    <Layout>
      <Seo title={`Home - ${Strings.companyName}`} />
      <h2 className="mbottom_1">
        {entities.entities && entities.entities.solutions_title}
      </h2>
      <div id="services" className={styles.services}>
        <div className={styles.services_buttons}>
          {solutions.solutions.map(elem => (
            <button
              key={elem.id}
              className={
                services === elem.id
                  ? styles.active_service +
                    " " +
                    styles[`active_service_${mode}`]
                  : ""
              }
              onClick={() => setServices(elem.id)}
            >
              <img
                alt={elem.attributes.name}
                style={{ width: "50px", height: "50px", objectFit: "cover" }}
                src={elem.attributes.image.data.attributes.url}
              />
              <div>
                <p> {elem.attributes.name}</p>
              </div>
            </button>
          ))}
        </div>
        <div className={styles.services_paragraph}>
          <GlassParagraph butaImage={false}>
            <Markdown>{selectedSolution?.attributes.long_description}</Markdown>
          </GlassParagraph>
        </div>
      </div>
      <Ideas form={true} title={Strings.haveAProjectInMind[language]} />
    </Layout>
  )
}

export default SolutionsPage
